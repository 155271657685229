// lodash
import first from "lodash/first";

import { useEffect } from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { useSelector, Loading, useDispatch } from "common";
import { selectError } from "selectors";
import { registrationVerifyEmailRequest } from "requests";
import { openSnackbar, setCurrentUserId } from "actions";
import { push } from "connected-next-router";
import { AssignNormalizedValuesPayload } from "types";
import routes from "routes";

export const VerifyEmail = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const error = useSelector((state) =>
    selectError(state, { request: registrationVerifyEmailRequest })
  );

  useEffect(() => {
    const { email, code } = router.query as { email?: string; code?: string };
    if (!email || !code) return;
    dispatch(
      registrationVerifyEmailRequest(
        { email, code },
        {
          onResponse: {
            successCallback: (success: AssignNormalizedValuesPayload) => {
              const currentUser = first(success.users);
              dispatch(setCurrentUserId(currentUser?.id));
              dispatch(
                openSnackbar({
                  variant: "success",
                  message: "Cuenta activada",
                })
              );
              dispatch(push(routes.publicarAnuncio()));
            },
          },
        }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  if (error?.data?.code === "invalid-link") {
    return (
      <Container>
        <h2>Link inválido</h2>
      </Container>
    );
  }

  if (error?.data?.code === "expired-link") {
    return (
      <Container>
        <h2>Tu link expiró</h2>
        <p>
          Te mandamos otro email con un link nuevo para que actives tu cuenta.
        </p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <h2>Error</h2>
      </Container>
    );
  }

  return <Loading />;
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
