// lodash
import startsWith from "lodash/startsWith";
import reject from "lodash/reject";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

// Material Components
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";

import styled from "@emotion/styled";
import { useState } from "react";
import {
  buildImageUrl,
  buildVideoUrl,
  useSelector,
  Form,
  InputError,
  useFileSelect,
  PostMediaPreview,
  AddMediaButton,
  useIsMobile,
} from "common";
import { selectUserMedia, selectCurrentUser } from "selectors";
import { SelectableGalleryModal } from "./SelectableGalleryModal";
import { formKeys } from "../../useFormInputs";
import { Asterisk, SectionTitle, FieldDescription } from "..";
import { TMedia } from "types";

type Props = {
  form: Form;
  mediaError: string;
};

export const SelectMedia = ({ form, mediaError }: Props) => {
  const isMobile = useIsMobile();
  const [showModal, setShowModal] = useState(false);
  const { getValuesObj, onChange } = form;
  const valuesObj = getValuesObj();
  const existingMedia: Array<TMedia> = valuesObj[formKeys.existingMedia];
  const user = useSelector(selectCurrentUser)!;
  const userMedia = useSelector((state) =>
    user ? selectUserMedia(state, user.id) : []
  );

  const { FileInput, openFileInput, onRemoveIndex, files } = useFileSelect({
    onChange: (files) => {
      onChange(formKeys.newFiles)(files);
      setShowModal(false);
    },
  });

  const openSelectMedia = () => {
    if (isEmpty(userMedia)) {
      openFileInput();
    } else {
      setShowModal(true);
    }
  };

  const onExistingImageRemove = (removeAtIndex: number) => {
    const newImages = reject(
      existingMedia,
      (_, index) => removeAtIndex === index
    );
    onChange(formKeys.existingMedia)(newImages);
  };

  return (
    <>
      <SectionTitle>ADJUNTAR FOTOS / VIDEOS</SectionTitle>
      <FileInput />
      <SFieldDescription>
        Publica únicamente fotos reales. Si tus fotos son robadas de internet,
        tu cuenta será bloqueada. Las fotos no deben ser muy pequeñas y no deben
        incluir marcas de agua. Agrega al menos una foto.
        <Asterisk>*</Asterisk>
      </SFieldDescription>
      {mediaError && <InputError>{mediaError}</InputError>}
      <AddMediaButtonContainer>
        <AddMediaButton onClick={openSelectMedia} />
      </AddMediaButtonContainer>
      {map(files, ({ file, url }, index) => (
        <PostMediaPreview
          key={index}
          file={file}
          mediaType={startsWith(file.type, "image") ? "image" : "video"}
          mediaSrc={url}
          onThisImageRemove={() => onRemoveIndex(index)}
        />
      ))}
      {map(existingMedia, (media, index) => (
        <PostMediaPreview
          key={media.id}
          media={media}
          mediaSrc={
            media.type === "image"
              ? buildImageUrl(media, "thumbnail")
              : buildVideoUrl(media)
          }
          mediaType={media.type}
          onThisImageRemove={() => onExistingImageRemove(index)}
        />
      ))}
      {showModal && (
        // We needed to add this to fix an error we were getting
        // when adding this modal to the redux reducer.
        <Dialog
          TransitionProps={{ role: "presentation" } as any}
          fullScreen={isMobile}
          open
          onClose={(_, reason) => {
            if (reason === "backdropClick") return;
            setShowModal(false);
          }}
          PaperComponent={Paper}
        >
          <SelectableGalleryModal
            form={form}
            openFileInput={openFileInput}
            setShowModal={setShowModal}
          />
        </Dialog>
      )}
    </>
  );
};

const SFieldDescription = styled(FieldDescription)`
  margin-bottom: 5px;
`;
const AddMediaButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
