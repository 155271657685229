// lodash
import chunk from "lodash/chunk";
import map from "lodash/map";
import keys from "lodash/keys";
import join from "lodash/join";

import styled from "@emotion/styled";
import { useDispatch, useCountry } from "common";
import { useRouter } from "next/router";
import { CATEGORIES, ESTADOS } from "consts";
import { setFilters } from "actions";
import Link from "next/link";
import { theme } from "theme";
import routes, { AnunciosQueryParams } from "routes";

const ANUNCIOS: Array<{
  href: string;
  label: string;
}> = map(CATEGORIES, (label, key) => ({
  href: routes.anuncios({ category: key as any }),
  label,
}));

export const LeftBar = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const country = useCountry();
  const { category: categoryKey, estado: estadoParamKey } =
    router.query as unknown as AnunciosQueryParams;

  const resetFilters = () => {
    dispatch(setFilters({}));
  };

  const renderEstados = (estadoIds: Array<string>) => (
    <Row key={join(estadoIds)}>
      {map(estadoIds, (estadoId) => (
        <Element key={estadoId} onClick={resetFilters}>
          <Link
            prefetch={false}
            href={routes.anuncios({
              category: categoryKey,
              estado: estadoId,
            })}
          >
            {ESTADOS[estadoId].name}
          </Link>
        </Element>
      ))}
    </Row>
  );

  const renderLocalidades = (localidadesIds: Array<string>) => (
    <Row key={join(localidadesIds)}>
      {map(localidadesIds, (localidadId) => (
        <Element key={localidadId} onClick={resetFilters}>
          <Link
            prefetch={false}
            href={routes.anuncios({
              category: categoryKey,
              estado: estadoParamKey,
              localidad: localidadId,
            })}
          >
            {ESTADOS[estadoParamKey!].localidades[localidadId].name}
          </Link>
        </Element>
      ))}
    </Row>
  );

  return (
    <Container>
      <Header>Anuncios en {country.name}</Header>
      {map(ANUNCIOS, ({ href, label }) => (
        <Row key={label}>
          <Element onClick={resetFilters}>
            <Link href={href} passHref prefetch={false}>
              {label}
            </Link>
          </Element>
        </Row>
      ))}

      {categoryKey !== "servicios-virtuales" && (
        <>
          {estadoParamKey && (
            <>
              <Header>{ESTADOS[estadoParamKey].name}</Header>
              {map(
                chunk(keys(ESTADOS[estadoParamKey].localidades).sort(), 2),
                renderLocalidades
              )}
              <MoreOptions>
                Para más localidades, utiliza el buscador.
              </MoreOptions>
            </>
          )}
          <Header>{country.name}</Header>
          {map(chunk(keys(ESTADOS).sort(), 2), renderEstados)}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  ${theme.breakpoints.only("sm")} {
    margin: 0 50px;
  }
`;
const Header = styled.h2`
  background: ${theme.palette.background.light};
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-weight: normal;
  font-size: 1.2rem;
  overflow: hidden;
`;
const Row = styled.div`
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
`;
const Element = styled.div`
  flex: 1;
  margin-right: 8px;

  a {
    color: ${theme.palette.blue.main};

    &:hover {
      text-decoration: underline;
    }
  }
`;
const MoreOptions = styled.p`
  text-align: center;
  font-size: 0.9rem;
  padding: 0 10px;
`;
