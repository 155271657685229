// lodash
import map from "lodash/map";

import styled from "@emotion/styled";
import Link from "next/link";
import routes from "routes";
import { theme } from "theme";
import { ESTADOS, CATEGORIES } from "consts";

export const Estados = ({
  categoryKey,
}: {
  categoryKey: keyof typeof CATEGORIES;
}) => (
  <Container>
    {map(ESTADOS, ({ name }, key) => (
      <Link
        key={key}
        href={routes.anuncios({
          category: categoryKey,
          estado: key,
        })}
      >
        {name + ", "}
      </Link>
    ))}
  </Container>
);

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    color: ${theme.palette.primary.main};
    margin-right: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    text-decoration: underline;
  }
`;
