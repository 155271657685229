// lodash
import map from "lodash/map";
// Material Components
import Grid from "@mui/material/Grid";

import styled from "@emotion/styled";
import Head from "next/head";
import { Category } from "./components";
import { DropdownFilter } from "common";
import { CATEGORIES } from "consts";

export const Home = () => {
  return (
    <Container>
      <Head>
        <title>&#x1F525; Anuncios eróticos - LOCALEROTICA</title>
        <meta
          key="desc"
          name="description"
          content="Busca anuncios eróticos reales y publica gratis."
        />
        <meta name="google" content="nositelinkssearchbox" key="sitelinks" />
        <meta name="google" content="notranslate" key="notranslate" />
      </Head>
      <DropdownFilter />
      <Grid container maxWidth={"1000px"} marginTop={"20px"}>
        {map(CATEGORIES, (_, serviceKey) => (
          <Category
            key={serviceKey}
            categoryKey={serviceKey as keyof typeof CATEGORIES}
          />
        ))}
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
