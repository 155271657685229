// Material Components
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// lodash
import first from "lodash/first";
import map from "lodash/map";

import Link from "next/link";
import { useRouter } from "next/router";
import {
  useCountry,
  useSelector,
  DrawerContentProps,
  UserListItem,
  DrawerListItem,
} from "common";
import { selectCurrentUser } from "selectors";
import { viewMap } from "./userViews";
import routes from "routes";

export const DrawerContent = ({ setMobileOpen }: DrawerContentProps) => {
  const router = useRouter();
  const view = first(router.query.param);
  const user = useSelector(selectCurrentUser);
  const country = useCountry();

  return (
    <List>
      <UserListItem user={user} />

      {map(viewMap, ({ title, Icon }, key) => (
        <Link href={routes.user([key])} passHref key={key}>
          <DrawerListItem
            onClick={() => setMobileOpen?.(false)}
            selected={view === key}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText
              primary={title}
              secondary={
                key === "creditos"
                  ? `$${user?.credits.toLocaleString() ?? 0} ${
                      country.prices.currency
                    }`
                  : undefined
              }
            />
          </DrawerListItem>
        </Link>
      ))}
    </List>
  );
};
