import { FormInput } from "common";

export const formKeys = {
  email: "email",
};

export const useFormInputs = (): Array<FormInput> => {
  const email = {
    key: formKeys.email,
    value: "",
    label: "Email",
    type: "email",
    required: true,
    noWhiteSpace: true,
    forceLowerCase: true,
  };

  return [email];
};
