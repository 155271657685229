import {
  Spacer,
  CATEGORIES_OPTIONS,
  Checkbox,
  Select,
  Form,
  TextField,
} from "common";
import { TITLE_LENGTH } from "..";
import { SectionTitle, FieldDescription } from ".";
import { formKeys } from "../useFormInputs";

export const Detalles = ({ form }: { form: Form }) => {
  const { getValuesObj } = form;
  const valuesObj = getValuesObj();

  return (
    <>
      <SectionTitle>DETALLES DEL ANUNCIO</SectionTitle>
      <Select
        formKey={formKeys.category}
        form={form}
        options={{ simple: CATEGORIES_OPTIONS }}
      />
      {valuesObj[formKeys.category] && (
        <FieldDescription underline>
          Sección exclusiva de anuncios para {valuesObj[formKeys.category]},
          cualquier otro tipo de anuncio será borrado o movido a su categoría
          sin previo aviso.
        </FieldDescription>
      )}

      <FieldDescription>
        Pon un título llamativo. Máximo {TITLE_LENGTH.maxLength} caracteres.
      </FieldDescription>
      <TextField formKey={formKeys.title} form={form} />

      <FieldDescription>
        Descríbete. No escribas todo en mayúsculas. Mínimo 200 caracteres y
        máximo 1,500.
      </FieldDescription>
      <TextField
        form={form}
        formKey={formKeys.text}
        multiline={{ minRows: 10 }}
      />
      <Spacer height={30} />
      <Checkbox formKey={formKeys.noAdvancePayment} form={form} />
    </>
  );
};
