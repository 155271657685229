// lodash
import first from "lodash/first";
import map from "lodash/map";
// Material Components
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { useRouter } from "next/router";
import Link from "next/link";
import {
  DrawerListItem,
  useSelector,
  DrawerContentProps,
  UserListItem,
} from "common";
import { selectCurrentUser } from "selectors";
import { viewMap } from "./adminViews";
import routes from "routes";

export const DrawerContent = ({ setMobileOpen }: DrawerContentProps) => {
  const router = useRouter();
  const user = useSelector(selectCurrentUser);
  const view = first(router.query.param);

  return (
    <List>
      <UserListItem user={user} />

      {map(
        viewMap,
        ({ title, Icon }, key) =>
          title && (
            <Link href={routes.admin([key])} key={key} passHref>
              <DrawerListItem
                onClick={() => setMobileOpen?.(false)}
                selected={key === view}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={title} />
              </DrawerListItem>
            </Link>
          )
      )}
    </List>
  );
};
