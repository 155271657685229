// lodash
import keys from "lodash/keys";
import first from "lodash/first";

import { useState } from "react";
import { viewMap } from "./adminViews";
import { useRouter } from "next/router";
import { DrawerContent } from "./DrawerContent";
import { useDispatch, Drawer, Loading, useAdminRoute } from "common";
import { push } from "connected-next-router";
import { Container } from "views/User";
import routes from "routes";

export const Admin = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const view = first(router.query.param);
  const [mobileOpen, setMobileOpen] = useState(false);

  const { user } = useAdminRoute();

  if (!user) return <Loading />;

  const Component = viewMap[view ?? first(keys(viewMap))!]?.Component;

  if (!Component) {
    dispatch(push(routes.admin()));
    return null;
  }

  return (
    <Container>
      <Drawer
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        drawerContent={<DrawerContent setMobileOpen={setMobileOpen} />}
      />
      <Component />
    </Container>
  );
};
