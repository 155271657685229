// lodash
import size from "lodash/size";
import map from "lodash/map";
import reject from "lodash/reject";
import includes from "lodash/includes";
import concat from "lodash/concat";

import { useState } from "react";
import styled from "@emotion/styled";
import {
  Button,
  useDispatch,
  Form,
  useSelector,
  Modal,
  Gallery,
  Header,
} from "common";
import { selectUserMedia, selectCurrentUser } from "selectors";
import { formKeys } from "../../useFormInputs";
import { closeModal } from "actions";
import { theme } from "theme";
import { TMedia } from "types";

type Props = {
  form: Form;
  openFileInput: () => void;
  setShowModal: (show: boolean) => void;
};

export const SelectableGalleryModal = ({
  form,
  openFileInput,
  setShowModal,
}: Props) => {
  const dispatch = useDispatch();
  const { onChange, getValuesObj } = form;
  const valuesObj = getValuesObj();
  const [selectedMedia, setSelectedMedia] = useState<Array<TMedia>>(
    valuesObj[formKeys.existingMedia]
  );
  const currentUser = useSelector(selectCurrentUser);
  const media = useSelector((state) => selectUserMedia(state, currentUser!.id));
  const userMedia = reject(media, (mediaItem) => mediaItem.verificationKit);

  const handleSelectMedia = (mediaItem: TMedia) => {
    const selectedMediaIds = map(selectedMedia, "id");
    // If selected includes the image we remove it,
    // if it doesn't, we add it
    const newSelected = includes(selectedMediaIds, mediaItem.id)
      ? reject(selectedMedia, { id: mediaItem.id })
      : concat(selectedMedia ?? [], mediaItem);
    setSelectedMedia(newSelected);
  };

  const onSubmit = () => {
    dispatch(closeModal());
    onChange(formKeys.existingMedia)(selectedMedia);
  };

  const hasVerificationKit = size(media) !== size(userMedia);

  return (
    <SModal
      title="Adjuntar fotos / videos"
      onCancel={() => setShowModal(false)}
      submitButton={{
        text: "Continuar",
        onClick: onSubmit,
      }}
    >
      <p>
        Puedes subir fotos / videos nuevos o elegir de lo que has subido
        previamente.
      </p>
      <Button
        onClick={() => {
          openFileInput();
        }}
      >
        Subir fotos / videos
      </Button>
      <Header>Mi Galería</Header>
      <GalleryContainer>
        <Gallery
          media={userMedia}
          selectable={{ selectedMedia, handleSelectMedia }}
          showStatus={hasVerificationKit}
        />
      </GalleryContainer>
    </SModal>
  );
};

const SModal = styled(Modal)`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 30px;
  }

  ${theme.breakpoints.up("sm")} {
    min-width: 536px;
  }

  h1 {
    margin-top: 40px;
    margin-bottom: 0;
  }
`;
const GalleryContainer = styled.div`
  width: 100%;
`;
