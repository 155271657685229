import { DropdownFilter } from "common";
import { LegalContainer, LegalContent } from "../PoliticaDePagos";

export const Recomendaciones = () => {
  return (
    <LegalContainer>
      <DropdownFilter />
      <LegalContent>
        <h1>Recomendaciones para el uso seguro del portal</h1>
        <p>
          Le compartimos aquí unas recomendaciones a considerar antes de
          contactar con alguna persona en cualquier portal de anuncios:
        </p>
        <ul>
          <li>
            No comparta datos personales como perfiles de redes sociales,
            documentos privados, direcciones de domicilio, datos bancarios, etc
          </li>
          <li>
            Evite compartir fotografías o videos que expongan su intimidad
          </li>
          <li>
            No realize pagos antes de iniciar el servicio solicitado presencial.
            Si el servicio que está contratando es virtual, puede tratar de
            contratar el servicio más barato para comprobar la veracidad del
            anuncio antes de contratar un servicio más caro
          </li>
          <li>
            Al mandar fotos, videos, o hacer videollamadas trate de no mostrar
            su rostro
          </li>
        </ul>
        <p>
          LOCALEROTICA no se hace responsable del uso del portal por parte de
          sus usuarios de manera negligente o irresponsable.
        </p>
      </LegalContent>
    </LegalContainer>
  );
};
