// lodash
import isEmpty from "lodash/isEmpty";
import times from "lodash/times";
import map from "lodash/map";

import Link from "next/link";
import { PublicacionListItem } from "common";
import { TPost } from "types";
import routes from "routes";
import { PublicacionList } from "views/ListaPublicaciones/components/Posts";

type Props = {
  posts: Array<TPost>;
};

export const Posts = ({ posts }: Props) => {
  if (!isEmpty(posts)) {
    return (
      <PublicacionList>
        {map(posts, (post, index) => {
          return (
            <Link
              href={routes.anuncios({
                category: post.category,
                estado: post.estado,
                localidad: post.localidad,
                postId: post.id,
                index: String(index),
              })}
              passHref
              key={`${index}-${post.id}`}
            >
              <a>
                <PublicacionListItem index={index} post={post} />
              </a>
            </Link>
          );
        })}
      </PublicacionList>
    );
  }

  return (
    <PublicacionList>
      {times(10, (index) => (
        <PublicacionListItem key={index} index={index} placeholder={{}} />
      ))}
    </PublicacionList>
  );
};
