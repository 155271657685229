// Material Components
import Grid from "@mui/material/Grid";
// lodash
import map from "lodash/map";

import { useState, useEffect } from "react";
import {
  useDispatch,
  Spacer,
  DropdownFilter,
  useCountry,
  useSelector,
} from "common";
import { setPostBackLink, setCurrentQueryResults } from "actions";
import { selectPostsByIds } from "selectors";
import Head from "next/head";
import { useRouter } from "next/router";
import { setFilters } from "actions";
import { getUserPostsRequest } from "requests";
import { Posts } from "./Posts";
import {
  ListaPublicacionesContainer,
  ListaPublicacionesContent,
} from "../ListaPublicaciones";
import { LeftBar } from "../ListaPublicaciones/components";
import { AssignNormalizedValuesPayload, TId } from "types";
import routes from "routes";

export const ListaUserPublicaciones = () => {
  const [postIds, setPostIds] = useState<Array<TId>>([]);
  const posts = useSelector((state) => selectPostsByIds(state, postIds));
  const dispatch = useDispatch();
  const country = useCountry();
  const router = useRouter();

  useEffect(() => {
    dispatch(setFilters({}));

    const userId = router.query.userId as string;
    if (!userId) return;

    dispatch(
      setPostBackLink({
        route: routes.userPosts({ userId }),
        text: "Anuncios",
      })
    );

    dispatch(
      getUserPostsRequest(
        {
          userId: userId,
          country: country.code,
        },
        {
          onResponse: {
            successCallback: (data: AssignNormalizedValuesPayload) => {
              const newPostIds = map(data.posts, "id");
              setPostIds(newPostIds);
              dispatch(setCurrentQueryResults(newPostIds));
            },
          },
        }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  return (
    <ListaPublicacionesContainer>
      <Head>
        <title>LOCALEROTICA</title>
      </Head>
      <DropdownFilter />
      <Spacer height={15} />

      <Grid container justifyContent="center" direction="row-reverse">
        <Grid item xs={12} md={8}>
          <ListaPublicacionesContent>
            <Posts posts={posts} />
          </ListaPublicacionesContent>
        </Grid>
        <Grid item xs={12} md={3}>
          <LeftBar />
        </Grid>
      </Grid>
    </ListaPublicacionesContainer>
  );
};
