import {
  FormInput,
  useForm,
  useDispatch,
  TextField,
  Modal,
  useSelector,
  useCountry,
} from "common";
import { selectIsFetching } from "selectors";
import { ESTADOS } from "consts";
import { closeModal, openModal } from "actions";
import { postLocalidadRequest } from "requests";

type Props = {
  estado: string;
  localidad: string;
};

const formKeys = {
  newLocalidad: "newLocalidad",
};

const useFormInputs = (localidad: string): Array<FormInput> => {
  const newLocalidad = {
    key: formKeys.newLocalidad,
    value: localidad ?? "",
    label: "Localidad",
    required: true,
  };

  return [newLocalidad];
};

export const AddLocalidadModal = ({ estado, localidad }: Props) => {
  const dispatch = useDispatch();
  const formInputs = useFormInputs(localidad);
  const form = useForm(formInputs);
  const valuesObj = form.getValuesObj();
  const country = useCountry();
  const isFetching = useSelector((state) =>
    selectIsFetching(state, { request: postLocalidadRequest })
  );

  const openSuccessModal = () => {
    dispatch(closeModal());
    dispatch(
      openModal({
        node: (
          <Modal
            title="Nueva Localidad"
            variant="success"
            cancelButton={{ text: "Continuar" }}
          >
            <p>
              Hemos recibido la petición para agregar la nueva localidad. Te
              avisaremos una vez que esté lista para que puedas crear tu
              anuncio.
            </p>
            <p>¡Gracias!</p>
          </Modal>
        ),
      })
    );
  };

  const estadoName = ESTADOS[estado].name;

  return (
    <Modal
      title="Agregar Localidad"
      loading={isFetching}
      submitButton={{
        text: "Agregar Localidad",
        onClick: () =>
          dispatch(
            postLocalidadRequest(
              {
                estado: estadoName,
                localidad: valuesObj[formKeys.newLocalidad],
              },
              { onResponse: { successCallback: openSuccessModal } }
            )
          ),
      }}
    >
      <p>
        Se agregará la localidad después de verificar los datos y te
        notificaremos.
        <br />
        Recibirás créditos de regalo si creas un anuncio verificado en la nueva
        localidad una vez que sea agregada.
      </p>
      <p>
        <strong>{country.estado}:</strong> {estadoName}
      </p>
      <TextField form={form} formKey={formKeys.newLocalidad} />
    </Modal>
  );
};
