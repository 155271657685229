import styled from "@emotion/styled";
import { useCountry, useSelector, FormInput } from "common";
import {
  selectMediaByIds,
  selectMediaIdsByPostId,
  selectPost,
  selectCurrentUser,
} from "selectors";
import { leyMexa } from "res";
import { theme } from "theme";
import { TId } from "types";
import routes from "routes";

export const formKeys = {
  estado: "estado",
  localidad: "localidad",
  barrio: "barrio",
  category: "category",
  title: "title",
  text: "text",
  noAdvancePayment: "noAdvancePayment",
  phoneNumber: "phoneNumber",
  showWhatsapp: "showWhatsapp",
  showPhoneNumber: "showPhoneNumber",
  newFiles: "newFiles",
  existingMedia: "existingMedia",
  acceptTerms: "acceptTerms",
  acceptLegal: "acceptLegal",
};

export const useFormInputs = ({
  postId,
}: {
  postId?: TId;
}): Array<FormInput> => {
  const country = useCountry();
  const post = useSelector((state) =>
    postId ? selectPost(state, postId) : undefined
  );
  const user = useSelector(selectCurrentUser);
  const mediaIds = useSelector((state) =>
    postId ? selectMediaIdsByPostId(state, postId) : undefined
  );
  const media = useSelector((state) => selectMediaByIds(state, mediaIds ?? []));

  const estado = {
    key: formKeys.estado,
    value: post?.estado ?? "",
    label: country.estado,
    required: true,
  };

  const localidad = {
    key: formKeys.localidad,
    value: post?.localidad ?? "",
    label: "Localidad",
    required: true,
  };

  const barrio = {
    key: formKeys.barrio,
    value: post?.barrio ?? "",
    label: "Barrio/Distrito (opcional)",
    placeholder: "Opcional",
  };

  const category = {
    key: formKeys.category,
    value: post?.category ?? "",
    label: "Categoría",
    required: true,
  };

  const title = {
    key: formKeys.title,
    value: post?.title ?? "",
    label: "Título",
    required: true,
  };

  const text = {
    key: formKeys.text,
    value: post?.text ?? "",
    label: "Descripción",
    required: true,
  };

  const phoneNumber = {
    key: formKeys.phoneNumber,
    value: post?.phoneNumber ?? user?.phoneNumbers[0] ?? "",
    label: "Teléfono",
    required: true,
  };

  const noAdvancePayment = {
    key: formKeys.noAdvancePayment,
    value: post?.noAdvancePayment ?? false,
    label: "No pido anticipo para reservar (opcional)",
  };

  const showWhatsapp = {
    key: formKeys.showWhatsapp,
    value: post?.showWhatsapp ?? false,
    label: "Mostrar WhatsApp en el anuncio",
  };

  const showPhoneNumber = {
    key: formKeys.showPhoneNumber,
    value: post?.showPhoneNumber ?? false,
    label: "Mostrar número de celular en el anuncio",
  };

  const newFiles = {
    key: formKeys.newFiles,
    value: [],
  };

  const existingMedia = {
    key: formKeys.existingMedia,
    value: media ?? [],
  };

  const acceptTerms = {
    key: formKeys.acceptTerms,
    value: Boolean(postId),
    label: (
      <>
        Soy mayor de edad y acepto las{" "}
        <CustomLink
          href={routes.legal()}
          target="_blank"
          rel="noopener noreferrer"
          key="val"
        >
          Condiciones de Uso
        </CustomLink>
        .
      </>
    ),
  };

  const acceptLegal = {
    key: formKeys.acceptLegal,
    value: Boolean(postId),
    label: (
      <>
        Declaro ser independiente. Creo este anuncio y ofrezco mis servicios de
        acompañamiento libremente y por voluntad propia. Soy consciente de la{" "}
        <CustomLink
          href={leyMexa}
          target="blank"
          rel="noopener noreferrer"
          key="val"
        >
          ley de mi país
        </CustomLink>{" "}
        y comprendo lo que es el delito de la Trata de Personas y declaro estar
        libre de tal delito.
      </>
    ),
  };

  return [
    estado,
    localidad,
    barrio,
    category,
    title,
    text,
    noAdvancePayment,
    phoneNumber,
    showWhatsapp,
    showPhoneNumber,
    newFiles,
    existingMedia,
    acceptTerms,
    acceptLegal,
  ];
};

const CustomLink = styled.a`
  color: ${theme.palette.blue.main};
  text-decoration: underline;
`;
