import {
  useDispatch,
  AddPhoneNumberModal,
  Select,
  InputError,
  Form,
  Checkbox,
  useSelector,
} from "common";
import { selectCurrentUser } from "selectors";
import { openModal } from "actions";
import { Asterisk, SectionTitle, FieldDescription } from ".";
import styled from "@emotion/styled";
import { formKeys } from "../useFormInputs";
import { MIN_ONE_COMMUNICATION_CHANNEL } from "../..";

export const Contacto = ({
  form,
  contactError,
}: {
  form: Form;
  contactError?: string;
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  const addPhoneNumber = () => {
    const { updateValues } = form;

    dispatch(
      openModal({
        node: (
          <AddPhoneNumberModal
            onPhoneAdded={(addedPhone) => {
              updateValues([{ key: formKeys.phoneNumber, value: addedPhone }]);
            }}
          />
        ),
        fullscreen: "mobile",
      })
    );
  };

  return (
    <>
      <SectionTitle blue>DATOS DE CONTACTO</SectionTitle>
      {user?.phoneNumbers.length && (
        <Select
          form={form}
          formKey={formKeys.phoneNumber}
          enableAddNew={{
            text: "Agregar nuevo número",
            onAdd: addPhoneNumber,
          }}
          options={{
            simple: user?.phoneNumbers.map((phoneNumber) => ({
              label: phoneNumber,
              value: phoneNumber,
            })),
          }}
        />
      )}
      <SFieldDescription>
        {MIN_ONE_COMMUNICATION_CHANNEL}
        <Asterisk>*</Asterisk>
      </SFieldDescription>
      {contactError && <InputError>{contactError}</InputError>}
      <Checkbox formKey={formKeys.showWhatsapp} form={form} />
      <Checkbox formKey={formKeys.showPhoneNumber} form={form} />
    </>
  );
};

const SFieldDescription = styled(FieldDescription)`
  margin-bottom: 5px;
`;
