import { FormInput } from "common";

export const formKeys = {
  password: "password",
  repeatPassword: "repeatPassword",
};

export const useFormInputs = (): Array<FormInput> => {
  const password = {
    key: formKeys.password,
    value: "",
    label: "Contraseña",
    required: true,
    type: "password",
  };

  const repeatPassword = {
    key: formKeys.repeatPassword,
    value: "",
    label: "Repite tu contraseña",
    required: true,
    type: "password",
  };

  return [password, repeatPassword];
};
