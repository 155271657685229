// Material Components
import Checkbox from "@mui/material/Checkbox";
import Hidden from "@mui/material/Hidden";
import FormControlLabel from "@mui/material/FormControlLabel";
// Material Icons
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

import styled from "@emotion/styled";
import { useSelector, useDispatch } from "common";
import { selectFilters } from "selectors";
import { setFilters } from "actions";
import { verifiedImageSrc, cameraImageSrc } from "res";
import { Filters } from "types";
import { theme } from "theme";

export const AttributeFilter = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);

  const updateFilters = (update: Partial<Filters>) => {
    dispatch(setFilters({ ...filters, ...update }));
  };

  return (
    <Container>
      <FormControlLabel
        control={
          <Checkbox
            inputProps={{ "aria-label": "Fotos verificadas" }}
            color="primary"
            checked={Boolean(filters.onlyVerified)}
            onChange={() =>
              updateFilters({ onlyVerified: !filters.onlyVerified })
            }
          />
        }
        label={
          <Label>
            <Tag src={verifiedImageSrc} alt="verificada" width="24px" />
            <Hidden smDown>
              <p>Fotos verificadas</p>
            </Hidden>
          </Label>
        }
      />

      <FormControlLabel
        control={
          <Checkbox
            inputProps={{ "aria-label": "Videos" }}
            color="primary"
            checked={Boolean(filters.onlyWithVideo)}
            onChange={() =>
              updateFilters({ onlyWithVideo: !filters.onlyWithVideo })
            }
          />
        }
        label={
          <Label>
            <Tag src={cameraImageSrc} alt="videos" width="45.22px" />
            <Hidden smDown>
              <p>Videos</p>
            </Hidden>
          </Label>
        }
      />

      <FormControlLabel
        control={
          <Checkbox
            inputProps={{ "aria-label": "Sin anticipo" }}
            color="primary"
            checked={Boolean(filters.onlyNoPrepay)}
            onChange={() =>
              updateFilters({ onlyNoPrepay: !filters.onlyNoPrepay })
            }
          />
        }
        label={
          <Label>
            <MoneyOffIcon />
            <Hidden smDown>
              <p>Sin anticipo</p>
            </Hidden>
          </Label>
        }
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-bottom: 30px;
  justify-content: space-around;
  height: 46px;
  gap: 10px;
`;
const Label = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 10px;
  }

  border-bottom: 2px solid white;
`;
const Tag = styled.img<{ width: string }>`
  height: 24px;
  width: ${({ width }) => width};

  ${theme.breakpoints.up("sm")} {
    margin-left: 10px;
  }
`;
