// Material Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";

import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { Button } from "common";
import { theme } from "theme";

export const Payments = () => {
  const router = useRouter();

  const { status } = router.query as { status: "success" | "cancelled" };

  const Icon = status === "success" ? <SCheckCircleIcon /> : <SReportIcon />;

  const closeWindow = () => {
    // Calling close like this closes the tab/window
    close();
  };

  return (
    <Container>
      <Logo>
        <h1 className="primary">LOCAL</h1>
        <h1 className="blue">EROTICA</h1>
      </Logo>
      <Payment>
        {Icon}
        <h1>¡Pago {status === "success" ? "completado" : "cancelado"}!</h1>
        <Button onClick={closeWindow} color="primary">
          Volver a LOCALEROTICA
        </Button>
      </Payment>
    </Container>
  );
};

const Container = styled.div`
  background: ${theme.palette.background.default};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;
const Logo = styled.div`
  display: flex;
  justify-content: center;

  .primary {
    color: ${theme.palette.primary.main};
    font-weight: normal;
  }

  .blue {
    color: ${theme.palette.blue.main};
    font-weight: normal;
  }
`;
const Payment = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const SCheckCircleIcon = styled(CheckCircleIcon)`
  color: ${theme.palette.success.main};
  background: white;
  border-radius: 100%;
  height: 50px !important;
  width: auto !important;
`;
const SReportIcon = styled(ReportIcon)`
  color: ${theme.palette.error.main};
  background: white;
  border-radius: 100%;
  height: 50px !important;
  width: auto !important;
`;
