// Material Components
import Grid from "@mui/material/Grid";

import styled from "@emotion/styled";
import Link from "next/link";
import { push } from "connected-next-router";
import { useDispatch } from "common";
import { CATEGORIES, postCategoryEnum } from "consts";
import { theme } from "theme";
import {
  trans,
  escorts,
  gigolos,
  escortsGay,
  masajesEroticos,
  serviciosVirtuales,
} from "res";
import routes from "routes";

const iconMap: { [category in typeof postCategoryEnum[number]]: string } = {
  escorts: escorts,
  gigolos: gigolos,
  travestis: trans,
  "escorts-gay": escortsGay,
  "masajes-eroticos": masajesEroticos,
  "servicios-virtuales": serviciosVirtuales,
};

export const Category = ({
  categoryKey,
}: {
  categoryKey: keyof typeof CATEGORIES;
}) => {
  const dispatch = useDispatch();
  const category = CATEGORIES[categoryKey];
  const iconSrc = iconMap[categoryKey];

  const navigate = () => {
    dispatch(push(routes.anuncios({ category: categoryKey })));
  };

  return (
    <Grid item xs={12} sm={6}>
      <Container>
        <Icon src={iconSrc} alt={category} onClick={navigate} />
        <Link
          href={routes.anuncios({ category: categoryKey })}
          passHref
          prefetch={false}
        >
          <h2>{category}</h2>
        </Link>
      </Container>
    </Grid>
  );
};

const Container = styled.div`
  margin: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h2 {
    color: ${theme.palette.primary.main};
    text-decoration: underline;
    margin: 10px 0;
    cursor: pointer;
  }
`;
const Icon = styled.img`
  height: 100px;
  width: 100px;
  cursor: pointer;
`;
