import { useEffect } from "react";
import { FormContainer } from "../components";
import {
  useSelector,
  Spacer,
  TextField,
  Button,
  useForm,
  useDispatch,
  REQUIRED_FIELD_ERROR,
  useCountry,
  InputError,
} from "common";
import { selectError, selectCurrentUser } from "selectors";
import { setCurrentUserId, openSnackbar } from "actions";
import { useRouter } from "next/router";
import { formKeys, useFormInputs } from "./useFormInputs";
import { replace } from "connected-next-router";
import { PASSWORD_MIN_LENGTH, PASSWORD_DONT_MATCH } from "views/Auth/Register";
import { passwordUpdateRequest } from "requests";
import { AssignNormalizedValuesPayload } from "types";
import routes from "routes";

export const UpdatePassword = () => {
  const dispatch = useDispatch();
  const formInputs = useFormInputs();
  const form = useForm(formInputs);
  const country = useCountry();
  const router = useRouter();
  const user = useSelector(selectCurrentUser);
  const error = useSelector((state) =>
    selectError(state, { request: passwordUpdateRequest })
  );

  useEffect(() => {
    if (user) dispatch(replace(routes.user()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { getValuesObj, updateValues } = form;

  const submit = () => {
    const valuesObj = getValuesObj();
    const password = valuesObj[formKeys.password];
    const errors = [];

    if (!password) {
      errors.push({ key: formKeys.password, error: REQUIRED_FIELD_ERROR });
    } else if (password.length < PASSWORD_MIN_LENGTH.length) {
      errors.push({
        key: formKeys.password,
        error: PASSWORD_MIN_LENGTH.message,
      });
    } else if (password !== valuesObj[formKeys.repeatPassword]) {
      errors.push({
        key: formKeys.repeatPassword,
        error: PASSWORD_DONT_MATCH,
      });
    }

    if (errors.length) {
      updateValues(errors);
      return;
    }

    const { email, code } = router.query as { email?: string; code?: string };

    dispatch(
      passwordUpdateRequest(
        {
          email: email!,
          country: country.code,
          newPassword: password,
          updatePasswordCode: code,
        },
        {
          onResponse: {
            successCallback: (success: AssignNormalizedValuesPayload) => {
              dispatch(
                openSnackbar({
                  variant: "success",
                  message: "Contraseña actualizada",
                })
              );
              dispatch(setCurrentUserId(success.users![0]!.id));
            },
          },
        }
      )
    );
  };

  return (
    <FormContainer>
      <h1>Contraseña nueva</h1>
      <Spacer height={30} />
      <TextField formKey={formKeys.password} form={form} />
      <Spacer height={20} />
      <TextField formKey={formKeys.repeatPassword} form={form} />
      <Spacer height={20} />
      {error && <InputError>{error.data?.message}</InputError>}
      {error?.data?.code === "code-expired" && (
        <p>
          Este link ya expiró. Te mandamos un email con un link nuevo para que
          actualices tu contraseña.
        </p>
      )}
      <Button onClick={submit}>Continuar</Button>
    </FormContainer>
  );
};
