import { DropdownFilter } from "common";
import { LegalContainer, LegalContent } from "../PoliticaDePagos";

export const Contacto = () => {
  return (
    <LegalContainer>
      <DropdownFilter />
      <LegalContent>
        <h1>Contacto</h1>
        <p>
          Para reportar un anuncio, por favor mánda un correo a{" "}
          <u>reportes@localerotica.com</u> con la razón de manera muy detallada
          por la cual estás reportando el anuncio. Incluye el link del anuncio a
          reportar en el correo.
        </p>
        <p>
          Si nunca creaste un anuncio y por algún motivo tus datos aparecen o te
          son solicitados sin tu consentimiento, deberás ponerte en contacto con
          nosotros de forma inmediata a <u>reportes@localerotica.com</u> para,
          entre ambos, localizar el problema y tomar las medidas necesarias para
          corregirlo.
        </p>
        <p>
          Para cualquier otro asunto, manda un correo a{" "}
          <u>info@localerotica.com</u>
        </p>
      </LegalContent>
    </LegalContainer>
  );
};
