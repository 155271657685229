// Material Components
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
// lodash
import take from "lodash/take";
import size from "lodash/size";
import isEmpty from "lodash/isEmpty";
import times from "lodash/times";
import map from "lodash/map";

import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Link from "next/link";
import { ESTADOS } from "consts";
import { useDispatch, Button, PublicacionListItem, usePosts } from "common";
import { setCurrentQueryResults } from "actions";
import routes, { AnunciosQueryParams } from "routes";
import { theme } from "theme";

type Props = {
  query: AnunciosQueryParams;
};

const PAGE_SIZE = 10;

export const Posts = ({ query }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { posts, isDoneFetching } = usePosts({ query });

  useEffect(() => {
    setPage(1);
  }, [query.category, query.localidad, query.estado]);

  useEffect(() => {
    const postIds = map(posts, "id");
    dispatch(setCurrentQueryResults(postIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts]);

  const loadMore = () => {
    setPage(page + 1);
  };

  const estado = query.estado && ESTADOS[query.estado].name;
  const localidad =
    query.localidad && ESTADOS[query.estado!].localidades[query.localidad].name;

  const paginatedPosts = take(posts, PAGE_SIZE * page);
  const hasMore = size(paginatedPosts) < size(posts);

  if (!isDoneFetching) {
    return (
      <PublicacionList>
        {times(10, (index) => (
          <PublicacionListItem key={index} index={index} placeholder={{}} />
        ))}
      </PublicacionList>
    );
  }

  if (!isEmpty(paginatedPosts)) {
    return (
      <PublicacionList>
        {map(paginatedPosts, (post, index) => {
          return (
            <Link
              href={routes.anuncios({
                category: post.category,
                estado: post.estado,
                localidad: post.localidad,
                postId: post.id,
                index: String(index),
              })}
              passHref
              key={`${index}-${post.id}`}
            >
              <a>
                <PublicacionListItem index={index} post={post} />
              </a>
            </Link>
          );
        })}
        {hasMore && (
          <LoadMoreContainer>
            <Button color="primary" onClick={loadMore}>
              Cargar más anuncios
            </Button>
          </LoadMoreContainer>
        )}
      </PublicacionList>
    );
  }

  return (
    <Empty>
      <SentimentVeryDissatisfiedIcon />
      <br />
      <br />
      No encontramos anuncios en{" "}
      <u>{localidad ?? estado ?? "esta categoría"}</u> con los filtros
      seleccionados
      <Link href={routes.publicarAnuncio()} passHref>
        <p>Publica un anuncio gratis aquí</p>
      </Link>
    </Empty>
  );
};

export const PublicacionList = styled.div`
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }
`;
const Empty = styled.h3`
  margin: 100px auto;
  text-align: center;
  width: 250px;

  svg {
    font-size: 5rem;
  }

  p {
    color: ${theme.palette.blue.main};
    text-decoration: underline;
    cursor: pointer;
    width: 200px;
    margin: 20px auto;
  }
`;
const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0;
`;
