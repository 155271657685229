import { InputError, Form, Checkbox } from "common";
import { formKeys } from "../useFormInputs";

export const Legal = ({
  form,
  legalError,
}: {
  form: Form;
  legalError?: string;
}) => (
  <>
    <Checkbox formKey={formKeys.acceptTerms} form={form} />
    <Checkbox formKey={formKeys.acceptLegal} form={form} />
    {legalError && <InputError>{legalError}</InputError>}
  </>
);
