// Material Icons
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// lodash
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import { useEffect } from "react";
import styled from "@emotion/styled";
import {
  useDispatch,
  useSelector,
  PublicacionListItem,
  DropdownFilter,
} from "common";
import { setPostBackLink, setCurrentQueryResults } from "actions";
import Link from "next/link";
import { selectFavouritePostIds, selectPostsByIds } from "selectors";
import { theme } from "theme";
import routes from "routes";

export const Favourite = () => {
  const dispatch = useDispatch();
  const favouritePostIds = useSelector(selectFavouritePostIds);
  const favouritePosts = useSelector((state) =>
    selectPostsByIds(state, favouritePostIds)
  );

  useEffect(() => {
    dispatch(setCurrentQueryResults(favouritePostIds));
    dispatch(setPostBackLink({ route: routes.favoritos(), text: "Favoritos" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <DropdownFilter />
      <h1>Favoritos</h1>
      {map(favouritePosts, (post, index) => (
        <PublicationListItemContainer key={post.id}>
          <Link
            href={routes.anuncios({
              category: post.category,
              estado: post.estado,
              localidad: post.localidad,
              postId: post.id,
              index: String(index),
            })}
            passHref
          >
            <a>
              <PublicacionListItem index={index} post={post} />
            </a>
          </Link>
        </PublicationListItemContainer>
      ))}
      {isEmpty(favouritePosts) && (
        <NoPosts>
          <h2>No has marcado ningún anuncio como favorito</h2>
          <p>
            Da click en el <FavoriteBorderIcon />
            en cualquier anuncio y aparecerá aquí
          </p>
        </NoPosts>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  background: ${theme.palette.background.default};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PublicationListItemContainer = styled.div`
  width: 90%;
  max-width: 900px;

  a {
    text-decoration: none;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
const NoPosts = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 90%;

  p {
    line-height: 30px;
    svg {
      margin: 0 3px;
      color: ${theme.palette.primary.main};
    }
  }
`;
