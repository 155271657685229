import { DropdownFilter } from "common";
import { LegalContainer, LegalContent } from "../PoliticaDePagos";

export const Legal = () => {
  return (
    <LegalContainer>
      <DropdownFilter />
      <LegalContent>
        <h1>Términos de Uso</h1>
        <p>Página con contenido para adultos +18 años.</p>
        <p>
          Bienvenido a LOCALEROTICA. Este sitio ofrece un servicio de hosting de
          anuncios de carácter personal, los cuales pueden ser creados
          gratuitamente.
        </p>
        <ul>
          <li>Todos los anunciantes reconocen tener mínimo 18 años.</li>
          <li>
            Todos los anunciantes reconocen haber creado sus anuncios de manera
            voluntaria.
          </li>
          <li>
            Todos los anunciantes han expresado tener la capacidad para
            comprender el delito de Trata de Personas y han manifestado estar
            libres de tal delito.
          </li>
          <li>
            Todos los anunciantes están conscientes que el crear un anucio en
            LOCALEROTICA no implica que tendrán contacto sexual con personas que
            los contacten.
          </li>
        </ul>
        <p>
          LOCALEROTICA no guarda ninguna relación laboral con ninguno de sus
          anunciantes, fuera de publicar los datos que fueron proporcionados por
          los anunciantes mismos para la creación de sus anuncios, por lo que
          LOCALEROTICA no participa de ninguna manera en los ofrecimientos
          renumerados o no, que puedan llegar a hacerse por los anunciantes con
          terceros que acepten estos <strong>Términos de Uso</strong>.
        </p>
        <p>
          Anunciantes que aporten información falsa a este portal, serán sujetos
          a la suspención de los servicios ofrecidos por LOCALEROTICA sin aviso
          previo.
        </p>
        <p>
          LOCALEROTICA se reserva el derecho a borrar, bloquear y/o no aprobar
          publicaciones a cualquier usuario. Así mismo, los usuarios pueden
          reportar cualquiera de los anuncios en el portal si se sospecha de
          actividades ilícitas, mandando un correo a{" "}
          <u>reportes@localerotica.com</u>. Se actuará con rotundidad.
        </p>
        <p>
          LOCALEROTICA se reserva el derecho de pedir a sus usuarios un
          documento legal válido con fotografía donde se muestre la edad para
          brindar y/o reanudar el servicio.
        </p>
        <p>
          Relaciones o contactos que se den entre anunciantes y terceros, son de
          exclusiva responsabilidad de los anunciantes, sin ser LOCALEROTICA
          intermediario de ningún tipo en dicho evento.
        </p>
        <p>Toda persona que ingrese y utilice este portal está aceptando:</p>
        <ul>
          <li>Ser mayor de edad en su país de origen</li>
          <li>
            Que el consumo del material en este portal será personal y no se
            expondrá a menores de edad
          </li>
          <li>
            Que consumir el material en este portal, sea viendo o descargando,
            no viola las leyes comunitarias de su lugar de residencia
          </li>
          <li>
            Que el uso del material (imágenes, videos, textos) de este portal
            sin permiso previo de LOCALEROTICA está prohibido y puede resultar
            en un procedimiento legal
          </li>
        </ul>
        <p>
          Si nunca creaste un anuncio y por algún motivo tus datos aparecen o te
          son solicitados sin tu consentimiento, deberás ponerte en contacto con
          nosotros de forma inmediata a <u>reportes@localerotica.com</u> para,
          entre ambos, localizar el problema y tomar las medidas necesarias para
          corregirlo.
        </p>
        <p>
          LOCALEROTICA no se hace responsable de los daños y perjuicios de
          ningún tipo derivados del uso negligente o malintencionado de la
          información de contacto hecha pública en los anuncios de los usuarios.
        </p>
        <p>
          LOCALEROTICA tampoco se hace responsable, indirectamente ni
          subsidiariamente, de la pérdida económica o de reputación, ni de
          ninguna clase de daños especiales, indirectos o emergentes,
          resultantes de la utilización del sitio web por parte del usuario.
          Esta exoneración de responsabilidad se aplicará en caso de que
          LOCALEROTICA no tenga conocimiento efectivo de que la actividad o
          información almacenada es ilícita o de que lesiona bienes o derechos
          de un tercero susceptibles a indemnización. En caso de tener el
          conocimiento efectivo, LOCALEROTICA actuará con diligencia para
          retirar los datos y contenidos o hacer imposible el acceso a ellos.
        </p>

        <h1>Política de Privacidad</h1>
        <p>
          LOCALEROTICA utiliza cookies para autenticación y autorización de sus
          usuarios.
        </p>
        <p>
          Este portal utiliza un servicio analítico de web proporcionado por
          Mixpanel. Mixpanel utiliza las llamadas “cookies”, archivos de texto
          que se instalan en el ordenador y que hacen posible el análisis del
          comportamiento de los usuarios en Internet. La información que genera
          la cookie acerca del uso que realiza el usuario de la página web se
          transmite y se almacena generalmente en los servidores de Mixpanel.
        </p>
        <p>
          Únicamente con autorización del usuario de la página, Mixpanel
          utilizará esta información con el fin de valorar el comportamiento del
          usuario en la página web, generar informes sobre las actividades de la
          web y ofrecer al dueño de la página servicios relacionados con el uso
          web y de Internet.
        </p>
        <p>
          El usuario puede evitar el almacenamiento de cookies configurando el
          navegador correspondientemente de manera que no se almacenen las
          cookies. Esta configuración puede afectar la plena funcionalidad de la
          página web.
        </p>
        <p>
          El contenido del portal que sea borrado por los usuarios, dejará de
          aparecer en los resultados dentro del portal inmediatamente, pero será
          archivado por un tiempo de un año antes de ser eliminado
          permanentemente. Esto con la intención de cumplir con la ley.
        </p>
        <p>
          LOCALEROTICA no se hace responsable de la posible aparición de los
          anuncios de un usuario en buscadores ajenos al portal una vez que se
          hayan dado de baja de nuestras bases de datos.
        </p>
        <p>LOCALEROTICA no almacena números de tarjeta de ningún usuario.</p>

        <h1>Propiedad intelectual</h1>
        <p>
          Al publicar un anuncio, el usuario concede a LOCALEROTICA un derecho
          no exclusivo, mundial, irrevocable y gratuito para la explotación de
          dicho anuncio, de manera que LOCALEROTICA podrá dar uso a este anuncio
          en cualquier medio y modalidad de explotación.
        </p>

        <h1>Disponibilidad del portal y servicios</h1>
        <p>
          LOCALEROTICA no garantiza disponibilidad, acceso y/o continuidad del
          funcionamiento del portal y sus servicios. LOCALEROTICA no es
          responsable, con los límites establecidos por la ley, de los daños
          causados al usuario como consecuencia de la indisponibilidad del
          portal o sus servicios.
        </p>

        <h1>Contenidos</h1>
        <p>
          LOCALEROTICA no revisa, controla, aprueba previamente ni hace propios
          los contenidos, productos, servicios, opiniones, comunicaciones,
          datos, archivos y cualquier clase de información de terceros recogidos
          en el portal. De la misma manera, LOCALEROTICA no garantiza la
          licitud, fiabilidad, utilidad, vercidad, exactitud, exhaustividad y
          actualidad de los contenidos, informaciones y servicios de terceros en
          el portal. LOCALEROTICA tampoco garantiza de ninguna manera que los
          usuarios del portal utilicen los servicios y/o contenidos del portal
          conforme con la ley, las normativas aplicables, el orden público ni
          las presentes Condiciones de Uso.
        </p>

        <h1>Modificaciones al sitio web</h1>
        <p>
          LOCALEROTICA se reserva el derecho de efectuar cualquier tipo de
          modificaciones que estime convenientes en el sitio web. Dichas
          modificaciones se harán de forma unilateral y sin previo aviso.
        </p>

        <h1>Legislación y fuero</h1>
        <p>
          Todas las cuestiones relativas a LOCALEROTICA se rigen en todos y cada
          uno de sus extremos por las leyes de Estonia y se someten a la
          jurisdicción de los Juzgados y Tribunales competentes del territorio
          de Estonia.
        </p>
        <p>
          Los usuarios, con expresa renuncia a su propio fuero, aceptan como
          legislación rectora del presente contrato en caso de controversia
          acerca de la interpretación o ejecución de las presentes cláusulas, la
          legislación de Estonia y se someten para la resolución de cuantos
          litigios pudieran derivarse del mismo a los Juzgados y Tribunales de
          Estonia.
        </p>
      </LegalContent>
    </LegalContainer>
  );
};
