// Material Components
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// lodash
import includes from "lodash/includes";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import map from "lodash/map";
import keys from "lodash/keys";
import startsWith from "lodash/startsWith";
import size from "lodash/size";

import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { ESTADOS } from "consts";
import {
  Spacer,
  REQUIRED_FIELD_ERROR,
  FormInput,
  useDispatch,
  useForm,
  useSelector,
  Loading,
  Button,
  usePrivateRoute,
  InputError,
  Modal,
  UploadMediaProgress,
} from "common";
import { push, goBack } from "connected-next-router";
import { selectIsFetching } from "selectors";
import { openModal, openSnackbar, createPost } from "actions";
import { getUserMediaRequest } from "requests";
import { MIS_ANUNCIOS } from "views/User/userViews";
import {
  Contacto,
  Localizacion,
  Detalles,
  SelectMedia,
  Legal,
} from "./components";
import { useFormInputs, formKeys } from "./useFormInputs";
import { Category } from "types";
import { theme } from "theme";
import routes from "routes";

export const MIN_ONE_COMMUNICATION_CHANNEL =
  "Selecciona al menos una opción de contacto";
const MIN_ONE_IMAGE = "Agrega al menos una foto";
export const TITLE_LENGTH = {
  maxLength: 80,
  error: "El título no puede contener más de 80 caracteres",
};

export const PublicarAnuncio = () => {
  const dispatch = useDispatch();
  const { user } = usePrivateRoute({ redirectRoute: routes.registro() });
  const router = useRouter();
  const { postId: _postId } = router.query as { postId?: Array<string> };
  const postId = first(_postId);
  const formInputs = useFormInputs({ postId });
  const form = useForm(formInputs);
  const { updateValues, getValuesObj, getIsValid } = form;
  const [contactError, setContactError] = useState<string>();
  const [legalError, setLegalError] = useState<string>();
  const [mediaError, setMediaError] = useState<string>();
  const isFetching = useSelector((state) =>
    selectIsFetching(state, { request: createPost })
  );

  useEffect(() => {
    if (!user) return;
    dispatch(getUserMediaRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const valuesObj = getValuesObj();

  const contactMethodSelected =
    valuesObj[formKeys.showWhatsapp] || valuesObj[formKeys.showPhoneNumber];

  const acceptTerms = valuesObj[formKeys.acceptTerms];
  const acceptLegal = valuesObj[formKeys.acceptLegal];
  const allLegalAccepted = acceptTerms && acceptLegal;

  const hasNewImages = some(
    map(valuesObj[formKeys.newFiles], (newMedia) =>
      startsWith(newMedia.file.type, "image")
    )
  );
  const hasExistingImages = some(
    map(valuesObj[formKeys.existingMedia], (media) => media.type === "image")
  );

  const imagesIncluded = hasNewImages || hasExistingImages;

  useEffect(() => {
    if (contactMethodSelected) setContactError(undefined);
  }, [contactMethodSelected]);

  useEffect(() => {
    if (allLegalAccepted) setLegalError(undefined);
  }, [allLegalAccepted]);

  useEffect(() => {
    if (imagesIncluded) setMediaError(undefined);
  }, [imagesIncluded]);

  if (!user) return <Loading />;

  const category: Category = valuesObj[formKeys.category];

  const formIsValid = () => {
    const errors: Array<FormInput> = [];

    if (category !== "servicios-virtuales") {
      if (!valuesObj[formKeys.estado]) {
        errors.push({ key: formKeys.estado, error: REQUIRED_FIELD_ERROR });
      }

      if (!valuesObj[formKeys.localidad]) {
        errors.push({ key: formKeys.localidad, error: REQUIRED_FIELD_ERROR });
      }
    }

    if (!category) {
      errors.push({ key: formKeys.category, error: REQUIRED_FIELD_ERROR });
    }

    if (!valuesObj[formKeys.title]) {
      errors.push({ key: formKeys.title, error: REQUIRED_FIELD_ERROR });
    } else if (size(valuesObj[formKeys.title]) >= TITLE_LENGTH.maxLength) {
      errors.push({ key: formKeys.title, error: TITLE_LENGTH.error });
    }

    if (!valuesObj[formKeys.text]) {
      errors.push({ key: formKeys.text, error: REQUIRED_FIELD_ERROR });
    } else if (size(valuesObj[formKeys.text]) >= 1500) {
      errors.push({
        key: formKeys.text,
        error:
          "La descripción sobrepasa los 1,500 caracteres. Hazla más corta.",
      });
    }

    if (!contactMethodSelected) {
      setContactError(MIN_ONE_COMMUNICATION_CHANNEL);
    }

    if (!imagesIncluded) {
      setMediaError(MIN_ONE_IMAGE);
    }

    if (!allLegalAccepted) {
      setLegalError("Debes aceptar ambas condiciones para continuar");
    }

    if (!isEmpty(errors)) {
      updateValues(errors);
    }

    return (
      isEmpty(errors) &&
      contactMethodSelected &&
      imagesIncluded &&
      allLegalAccepted
    );
  };

  const submit = () => {
    if (!formIsValid()) {
      return;
    }

    // We set some estado and some localidad if the user selects "servicios-virtuales"
    // We do this manually, because we hide the form inputs for location when "servicios-virtuales"
    const firstEstado = first(keys(ESTADOS))!;
    const firstLocalidad = first(keys(ESTADOS[firstEstado].localidades));

    dispatch(
      createPost(
        {
          updatePostId: postId,
          estado:
            category === "servicios-virtuales"
              ? firstEstado
              : valuesObj[formKeys.estado],
          localidad:
            category === "servicios-virtuales"
              ? firstLocalidad
              : valuesObj[formKeys.localidad],
          barrio: valuesObj[formKeys.barrio],
          category,
          title: valuesObj[formKeys.title],
          text: valuesObj[formKeys.text],
          noAdvancePayment: valuesObj[formKeys.noAdvancePayment],
          phoneNumber: valuesObj[formKeys.phoneNumber],
          showWhatsapp: valuesObj[formKeys.showWhatsapp],
          showPhoneNumber: valuesObj[formKeys.showPhoneNumber],
          newFiles: valuesObj[formKeys.newFiles],
          existingMedia: valuesObj[formKeys.existingMedia],
        },
        {
          onResponse: {
            successCallback: () => {
              dispatch(
                openSnackbar({
                  variant: "success",
                  message: `Anuncio ${postId ? "actualizado" : "creado"}`,
                })
              );
            },
            errorCallback: (error) => {
              dispatch(
                openSnackbar({
                  variant: "error",
                  message: "Error al generar anuncio",
                })
              );

              const code = error.payload?.error?.payload?.data?.code;

              if (
                includes(
                  ["too-many-posts", "too-many-posts-location-category"],
                  code
                )
              ) {
                dispatch(
                  openModal({
                    node: (
                      <Modal
                        variant="warning"
                        title="Error"
                        cancelButton={{ text: "Continuar" }}
                      >
                        <ErrorModalContent>
                          <p>
                            Puedes tener un máximo de{" "}
                            <u>
                              <strong>10</strong>
                            </u>{" "}
                            anuncios en total , pero no más de{" "}
                            <u>
                              <strong>3</strong>
                            </u>{" "}
                            anuncios en una misma categoría y ubicación.
                          </p>
                          <p>
                            En{" "}
                            <span
                              onClick={() =>
                                dispatch(push(routes.user([MIS_ANUNCIOS])))
                              }
                            >
                              Mis Anuncios
                            </span>{" "}
                            puedes editar alguno de tus anuncios existentes o
                            borrar alguno para crear otro nuevo. También puedes
                            tratar seleccionando una ubicación o categoría
                            diferente.
                          </p>
                        </ErrorModalContent>
                      </Modal>
                    ),
                  })
                );
              }
            },
          },
        }
      )
    );
  };

  const cancelEdit = () => {
    dispatch(goBack());
  };

  const isValid = getIsValid() && !legalError && !contactError && !mediaError;

  return (
    <Container>
      <ResponsiveForm>
        <Title>{postId ? "Editar" : "Publicar"} Anuncio</Title>
        {postId && (
          <Button onClick={cancelEdit}>
            <ButtonContent>
              <ArrowBackIcon />
              Cancelar
            </ButtonContent>
          </Button>
        )}
        <Spacer height={20} />
        <Localizacion form={form} />

        <Detalles form={form} />
        <Spacer height={30} />

        <Contacto form={form} contactError={contactError} />
        <Spacer height={30} />

        <SelectMedia form={form} mediaError={mediaError ?? ""} />
        <Spacer height={30} />

        <Legal form={form} legalError={legalError} />

        {!isValid && (
          <>
            <FlexEndContainer>
              <InputError>Hay errores en la forma</InputError>
            </FlexEndContainer>
            <Spacer height={10} />
          </>
        )}
        <FlexEndContainer>
          {postId && (
            <Button color="secondary" onClick={cancelEdit}>
              Cancelar
            </Button>
          )}

          <Button onClick={submit} color="primary">
            {postId ? "Actualizar" : "Publicar"} Anuncio
          </Button>
        </FlexEndContainer>
      </ResponsiveForm>
      {isFetching && <UploadMediaProgress />}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > h1 {
    font-size: 1.9rem;
    text-align: center;
  }
`;
const Title = styled.h1`
  text-align: center;
`;
const ResponsiveForm = styled.div`
  flex: 1;
  max-width: 900px;
  padding: 10px;
  width: 100%;
  margin-bottom: 100px;
`;
const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;
const ErrorModalContent = styled.div`
  text-align: center;

  span {
    cursor: pointer;
    text-decoration: underline;
    color: ${theme.palette.blue.main};
  }
`;
