// Material Components
import Grid from "@mui/material/Grid";

import { useEffect } from "react";
import styled from "@emotion/styled";
import {
  useDispatch,
  Spacer,
  DropdownFilter,
  useCountry,
  useAnunciosMetadata,
  Breadcrumbs,
} from "common";
import Head from "next/head";
import { setPostBackLink, setFilters } from "actions";
import { ESTADOS, CATEGORIES } from "consts";
import { AttributeFilter, LeftBar, Posts } from "./components";
import { theme } from "theme";
import routes, { AnunciosQueryParams } from "routes";

type Props = {
  params: AnunciosQueryParams; // This comes from getStaticProps
};

export const ListaPublicaciones = (props: Props) => {
  const dispatch = useDispatch();
  const country = useCountry();
  const {
    category: categoryKey,
    estado: estadoKey,
    localidad: localidadKey,
  } = props.params;

  const location = estadoKey
    ? localidadKey
      ? ESTADOS[estadoKey].localidades[localidadKey].name
      : ESTADOS[estadoKey].name
    : country.name;
  const category = CATEGORIES[categoryKey];

  useEffect(() => {
    dispatch(setFilters({}));
    dispatch(
      setPostBackLink({
        route: routes.anuncios({
          category: categoryKey,
          estado: estadoKey,
          localidad: localidadKey,
        }),
        text: location,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryKey, estadoKey, localidadKey]);

  const { title, description } = useAnunciosMetadata({ categoryKey, location });

  return (
    <ListaPublicacionesContainer>
      <Head>
        {title}
        {description}
      </Head>
      <DropdownFilter />
      <Spacer height={15} />

      <Grid container justifyContent="center" direction="row-reverse">
        <Grid item xs={12} md={8}>
          <ListaPublicacionesContent>
            <Header>
              {category} {location}
            </Header>
            <SBreadcrumbs />

            <AttributeFilter />
            <Posts query={props.params} />
          </ListaPublicacionesContent>
        </Grid>
        <Grid item xs={12} md={3}>
          <LeftBar />
        </Grid>
      </Grid>
    </ListaPublicacionesContainer>
  );
};

export const ListaPublicacionesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ListaPublicacionesContent = styled.div`
  width: 100%;

  ${theme.breakpoints.up("sm")} {
    padding: 0 40px;
  }
`;
const SBreadcrumbs = styled(Breadcrumbs)`
  margin: 15px 25px !important;

  ${theme.breakpoints.up("sm")} {
    margin-left: 2px !important;
  }
`;
const Header = styled.h1`
  margin: 0;

  ${theme.breakpoints.down("sm")} {
    text-align: center;
  }
`;
