import styled from "@emotion/styled";
import { Header } from "common";
import { theme } from "theme";

export const SectionTitle = styled(Header)`
  font-size: 1.6rem;
`;
export const FieldDescription = styled.p<{ underline?: boolean }>`
  font-size: 1rem;
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
`;
export const Asterisk = styled.span`
  color: ${theme.palette.primary.main};
`;
