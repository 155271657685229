import { useEffect } from "react";
import {
  Spacer,
  ESTADOS_OPTIONS,
  LOCALIDADES_OPTIONS,
  Form,
  TextField,
  Autocomplete,
  useDispatch,
  FormInput,
} from "common";
import { AddLocalidadModal } from "./AddLocalidadModal";
import { openModal } from "actions";
import { SectionTitle } from "..";
import { Category } from "types";
import { formKeys } from "../../useFormInputs";

export const Localizacion = ({ form }: { form: Form }) => {
  const dispatch = useDispatch();
  const { updateValues, getValuesObj } = form;
  const valuesObj = getValuesObj();
  const estado = valuesObj[formKeys.estado];
  const category = valuesObj[formKeys.category];

  useEffect(() => {
    const update: FormInput = { key: formKeys.localidad, disabled: !estado };
    if (!estado) update.value = "";

    updateValues([update]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  const addLocalidad = (localidad: string) => {
    dispatch(
      openModal({
        node: <AddLocalidadModal estado={estado} localidad={localidad} />,
      })
    );
  };

  if ((category as Category) === "servicios-virtuales") return null;

  return (
    <>
      <SectionTitle blue>LOCALIZACIÓN DEL ANUNCIO</SectionTitle>
      <Autocomplete
        formKey={formKeys.estado}
        form={form}
        options={ESTADOS_OPTIONS()}
        noOptionsText="Sin resultados"
      />
      <Spacer height={30} />
      <Autocomplete
        formKey={formKeys.localidad}
        form={form}
        options={estado ? LOCALIDADES_OPTIONS(estado) : []}
        enableAddNew={{ onAdd: addLocalidad }}
      />
      <Spacer height={30} />
      <TextField formKey={formKeys.barrio} form={form} />
      <Spacer height={30} />
    </>
  );
};
