// lodash
import isEmpty from "lodash/isEmpty";
// Material Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useState } from "react";
import styled from "@emotion/styled";
import routes from "routes";
import Link from "next/link";
import { forgotPasswordRequest } from "requests";
import {
  Button,
  useCountry,
  useSelector,
  TextField,
  useDispatch,
  useForm,
  REQUIRED_FIELD_ERROR,
  Spacer,
} from "common";
import { openSnackbar } from "actions";
import { selectIsFetching } from "selectors";
import { isValidEmail } from "utils";
import { INVALID_EMAIL_ERROR } from "../Login";
import { CodeDescription } from "../Register";
import { formKeys, useFormInputs } from "./useFormInputs";
import { FlexRow, CustomLink, FormContainer } from "../components";
import { theme } from "theme";

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [hasSubmittedEmail, setHasSubmittedEmail] = useState(false);
  const isFetchingForgotPasswordRequest = useSelector((state) =>
    selectIsFetching(state, { request: forgotPasswordRequest })
  );
  const country = useCountry();
  const formInputs = useFormInputs();
  const form = useForm(formInputs);

  const submit = () => {
    const { getValuesObj, updateValues } = form;
    const valuesObj = getValuesObj();
    const email = valuesObj[formKeys.email];
    const errors = [];

    if (!email) {
      errors.push({ key: formKeys.email, error: REQUIRED_FIELD_ERROR });
    } else if (!isValidEmail(email)) {
      errors.push({ key: formKeys.email, error: INVALID_EMAIL_ERROR });
    }

    if (!isEmpty(errors)) {
      updateValues(errors);
      return;
    }

    dispatch(
      forgotPasswordRequest(
        { email, country: country.code },
        {
          onResponse: {
            successCallback: () => {
              setHasSubmittedEmail(true);
              updateValues([{ key: formKeys.email, disabled: true }]);
            },
            errorCallback: () => {
              openSnackbar({ variant: "error", message: "Error" });
              updateValues([
                { key: email, error: "Asegúrate de que tu email es correcto" },
              ]);
            },
          },
        }
      )
    );
  };

  return (
    <FormContainer>
      <h1>Recuperar contraseña</h1>
      <p>Ingresa los datos para recuperar tu contraseña</p>
      <TextField form={form} formKey={formKeys.email} />
      {!hasSubmittedEmail ? (
        <ButtonContainer>
          <Button
            color="primary"
            onClick={submit}
            loading={isFetchingForgotPasswordRequest}
          >
            Continuar
          </Button>
        </ButtonContainer>
      ) : (
        <Success>
          <ColoredIcon color={theme.palette.success.main}>
            <CheckCircleIcon />
          </ColoredIcon>
          <CodeDescription>
            Si el email está registrado con una cuenta, mandamos un correo con
            instrucciones para recuperar tu contraseña.
          </CodeDescription>
        </Success>
      )}
      <FlexRow>
        ¿Recuerdas tu contraseña?{" "}
        <Link href={routes.login()} passHref>
          <CustomLink>Inicia sesión</CustomLink>
        </Link>
      </FlexRow>

      <Spacer height={20} />

      <FlexRow>
        ¿No tienes cuenta?{" "}
        <Link href={routes.registro()} passHref>
          <CustomLink>¡Regístrate gratis!</CustomLink>
        </Link>
      </FlexRow>
    </FormContainer>
  );
};

const ButtonContainer = styled.div`
  margin: 40px 0;
`;
const ColoredIcon = styled.div<{ color: string }>`
  svg {
    height: 70px;
    width: 70px;
    color: ${({ color }) => color};
  }
`;
const Success = styled.div`
  margin: 30px 0;
`;
