// lodash
import map from "lodash/map";

import styled from "@emotion/styled";
import { theme } from "theme";
import {
  useCountry,
  Spacer,
  PublicacionListItem,
  DropdownFilter,
} from "common";

export const PoliticaDePagos = () => {
  const country = useCountry();

  return (
    <LegalContainer>
      <DropdownFilter />
      <LegalContent>
        <h1>Política de Pagos</h1>
        <p>
          Los usuarios deben primero cargar créditos a sus cuentas y después
          podrán gastar dichos créditos para la contratación de diferentes
          paquetes para sus anuncios.
        </p>
        <h3>Recargas:</h3>
        <ul>
          {map(country.prices.recargas, (recarga, code) => (
            <li key={code}>
              {country.prices.currencySymbol}
              {recarga.price.toLocaleString()} {country.prices.currency}
            </li>
          ))}
        </ul>

        <Spacer height={30} />

        <p>Hay dos tipos de paquetes disponibles para los anunciantes:</p>
        <h3>
          <u>1. TOP</u>
        </h3>
        <p>
          El paquete TOP hace que tu anuncio sea de los primeros en aparecer. Se
          muestran primero todos los anuncios TOP y después el resto.
        </p>
        {map(country.prices.top, ({ durationDays, options }, code) => (
          <ul key={code}>
            <li>
              <strong>{durationDays} días</strong>
            </li>
            <ul>
              {map(options, ({ durationHours, price }, code) => (
                <li key={code}>
                  {durationHours} horas - {country.prices.currencySymbol}
                  {price.toLocaleString()} {country.prices.currency}
                </li>
              ))}
            </ul>
          </ul>
        ))}
        <Spacer height={10} />

        <PublicacionListItem index={0} placeholder={{ top: true }} />
        <PublicacionListItem index={1} placeholder={{ top: true }} />
        <PublicacionListItem index={0} placeholder={{}} />

        <Spacer height={30} />

        <h3>
          <u>2. DESTACADO</u>
        </h3>
        <p>
          Los anuncios destacados son más llamativos para las personas viendo
          los anuncios, pero no afectan que tan arriba aparece el anuncio en los
          resultados.
        </p>
        {map(country.prices.featured, ({ durationDays, options }, code) => (
          <ul key={code}>
            <li>
              <strong>{durationDays} días</strong>
            </li>
            <ul>
              {map(options, ({ durationHours, price }, code) => (
                <li key={code}>
                  {durationHours} horas - {country.prices.currencySymbol}
                  {price.toLocaleString()} {country.prices.currency}
                </li>
              ))}
            </ul>
          </ul>
        ))}

        <Spacer height={10} />

        <PublicacionListItem index={0} placeholder={{}} />
        <PublicacionListItem index={1} placeholder={{ featured: true }} />
        <PublicacionListItem index={0} placeholder={{}} />

        <Spacer height={10} />

        <h1>Entrega de los servicios</h1>
        <p>
          Si el usuario recarga créditos pagando con una tarjeta bancaria, los
          créditos se verán reflejados de manera inmediata.
        </p>
        <p>
          Si usted realizó un pago con su tarjeta bancaria y los créditos no se
          ven reflejados inmediatamente, contáctenos al correo{" "}
          <u>pagos@localerotica.com</u>. Después de verificar que se ha
          realizado el pago, actualizaremos sus créditos lo más pronto posible.
        </p>
        <h1>Pagos recurrentes</h1>
        <p>
          LOCALEROTICA no realiza pagos recurrentes y no almacena los datos de
          pago del usuario. Cada vez que el usuario quiera realizar un pago,
          tendrá que introducir los datos de su tarjeta.
        </p>
        <h1>Devoluciones</h1>
        <p>
          El usuario tiene un periodo de 24 horas después de realizar una
          recarga para solicitar una devolución de su dinero.
        </p>
        <p>
          El usuario puede también en cualquier momento solicitar la devolución
          de los créditos utilizados para la contratación de un servicio. En
          este caso, se le regresarían los créditos al usuario después de
          restarle los créditos correspondientes al tiempo que el usuario gozó
          de los servicios pagados.
        </p>
        <p>
          Para comenzar el proceso de devolución, por favor contáctenos al
          correo <u>pagos@localerotica.com</u>.
        </p>
        <p>
          Si un usuario habitualmente pide la devolución de su dinero o de
          créditos, puede ver su cuenta suspendida o eliminada.
        </p>
        <h1>Políticas de Fraude</h1>
        <p>
          Los usuarios responsables del uso fraudulento de tarjetas de
          crédito/débito u operaciones bancarias serán bloqueados
          permanentemente del sitio y sus datos podrán ser comunicados a las
          autoridades pertinentes.
        </p>
        <h1>Problemas en pagos y Disputas</h1>
        <p>
          Para cualquier problema o disputa, contactarnos a través del correo{" "}
          <u>pagos@localerotica.com</u>.
        </p>
      </LegalContent>
    </LegalContainer>
  );
};

export const LegalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const LegalContent = styled.div`
  width: 800px;
  max-width: 90%;
  margin: 0 auto;

  p {
    line-height: 1.3em;
  }

  a {
    color: ${theme.palette.blue.main};
    text-decoration: underline;
  }
`;
