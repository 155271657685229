// lodash
import keys from "lodash/keys";
import first from "lodash/first";

import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { viewMap } from "./userViews";
import { useRouter } from "next/router";
import {
  useIsMobile,
  Drawer,
  Loading,
  usePrivateRoute,
  useDispatch,
} from "common";
import { DrawerContent } from "./DrawerContent";
import { push } from "connected-next-router";
import routes from "routes";

export const User = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isMobile = useIsMobile();
  const view = first(router.query.param);
  const [isInitialNav, setIsInitialNav] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);

  const { user } = usePrivateRoute();

  useEffect(() => {
    if (!isInitialNav || !user) return;

    if (isMobile) {
      setMobileOpen(true);
      setIsInitialNav(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  if (!user) return <Loading />;

  const Component = viewMap[view ?? first(keys(viewMap))!]?.Component;

  if (!Component) {
    dispatch(push(routes.user()));
    return null;
  }

  return (
    <Container>
      <Drawer
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        drawerContent={<DrawerContent setMobileOpen={setMobileOpen} />}
      />
      <Component />
    </Container>
  );
};

export const Container = styled.div`
  flex: 1;
  display: flex;
`;
